.orderManager-container {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.search-bar {
    width: 100%;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.text-field {
    width: 10%;
}

.image-card {
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    text-align: center;
    width: 100%;
    height: 460px;
    /* 固定高度 */
    overflow-y: auto;
    /* 超出内容滚动 */
}

.control-panel {
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 100%;
    height: 460px;
    /* 固定高度 */
    overflow-y: auto;
    /* 超出内容滚动 */
}